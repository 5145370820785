const isCameraDevice = (desc: string): boolean => {
  console.log(desc);
  if (!desc) {
    return false;
  }

  const cameraDevicePrefixes = [
    'spvrc',
    'nok',
    'axl',
    'cor',
    'pwa',
    'nir',
    'pwd',
    'pwe',
    'pwf',
    'pwh',
    'pwj',
    'pwk',
    'pwm',
    'pwn',
    'paa',
    'pab',
  ];

  return (
    cameraDevicePrefixes.findIndex((prefix) =>
      desc.toLowerCase().includes(prefix),
    ) !== -1
  );
};

const isChimeDevice = (desc: string): boolean => {
  if (!desc) {
    return false;
  }
  return desc.toLowerCase().includes('plb');
};

const isRgbwcBulbDevice = (desc: string): boolean => {
  if (!desc) {
    return false;
  }
  const bulbDevicePrefixes = ['peb', 'pep', 'per', 'pes'];
  return (
    bulbDevicePrefixes.findIndex((prefix) =>
      desc.toLowerCase().includes(prefix),
    ) !== -1
  );
};

const isRgbStripDevice = (desc: string): boolean => {
  if (!desc) {
    return false;
  }
  return desc.toLowerCase().includes('pja');
};

const isRgbwStripDevice = (desc: string): boolean => {
  if (!desc) {
    return false;
  }
  return desc.toLowerCase().includes('pjc');
};

const isRgbicStripDevice = (desc: string): boolean => {
  if (!desc) {
    return false;
  }
  const rgbicDevicePrefixes = ['pjj', 'pjg', 'pjf'];
  return (
    rgbicDevicePrefixes.findIndex((prefix) =>
      desc.toLowerCase().includes(prefix),
    ) !== -1
  );
};

const isIndoorSmartPlug = (desc: string): boolean => {
  if (!desc) {
    return false;
  }
  return desc.toLowerCase().includes('pej');
};

const isDimmerSwitch = (desc: string): boolean => {
  if (!desc) {
    return false;
  }
  return desc.toLowerCase().includes('pjn');
};

const supportedDeviceTypes = [
  'device',
  'camera',
  'chime',
  'rgbwcbulb',
  'rgbstrip',
  'rgbwstrip',
  'rgbicstrip',
  'indoorSmartPlug',
  'dimmerSwitch',
];

const defaultDeviceMap = (): { [key: string]: ValidationDevices } => {
  const deviceMap: { [key: string]: ValidationDevices } = {
    camera: {
      label: 'Cameras',
      devices: [],
    },
    chime: {
      label: 'Chimes',
      devices: [],
    },
    dimmerSwitch: {
      label: 'Dimmer Switches',
      devices: [],
    },
    rgbwcbulb: {
      label: 'RGBWC Bulbs',
      devices: [],
    },
    rgbstrip: {
      label: 'RBG Strips',
      devices: [],
    },
    rgbwstrip: {
      label: 'RBGW Strips',
      devices: [],
    },
    rgbicstrip: {
      label: 'RBGIC Strips',
      devices: [],
    },
    indoorSmartPlug: {
      label: 'Indoor SmartPlug',
      devices: [],
    },
    unknown: {
      label: 'Other Devices',
      devices: [],
    },
  };
  return deviceMap;
};

const deviceHelpers = {
  isCameraDevice,
  isChimeDevice,
  isRgbwcBulbDevice,
  isRgbStripDevice,
  isRgbwStripDevice,
  isRgbicStripDevice,
  isIndoorSmartPlug,
  isDimmerSwitch,
  supportedDeviceTypes,
  defaultDeviceMap,
};

export default deviceHelpers;
