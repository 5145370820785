const snapshotEvent = 'SnapShot';
const startRecordVideoEvent = 'RecordVideo';
const stopRecordVideoEvent = 'StopRecordVideo';
const playSirenEvent = 'PlaySiren';
const stopPlaySirenEvent = 'StopSiren';
const imageMotionEnableUpdate = (nextValue: boolean) => ({
  imageMotionEnable: nextValue,
});
const soundDetectionEnableUpdate = (nextValue: boolean) => ({
  soundDetectEnable: nextValue,
});
const soundDetectionEvent = 'SoundDet';
const doorbellPressEvent = 'DoorBellPress';
const PTZMotionTracking = 'PTZMotionTracking';

const PTZMotionTrackingUpdate = (nextValue: boolean) => ({
  PTZMotionTracking: nextValue,
});

const flip0 = {
  imageFlip: '0',
};
const flip1 = {
  imageFlip: '1',
};
const flip2 = {
  imageFlip: '2',
};
const flip3 = {
  imageFlip: '3',
};

const timeZoneOptions = [
  'EST',
  'EST5EDT',
  'CST6CDT',
  'MST7MDT',
  'PST8PDT',
  'HST',
  'CET',
  'GB',
];
const timeZoneUpdate = (nextValue: string) => ({
  timeZone: nextValue,
});

const imageQualityOptions = ['HIGH', 'MED', 'LOW'];
const imageQualityUpdate = (nextValue: string) => ({
  imageQuality: nextValue,
});

const imageMotionOptions = ['OFF', 'HIGH', 'MED', 'LOW'];
const imageMotionUpdate = (nextValue: string) => ({
  imageMotion: nextValue,
});

const soundDetOptions = ['OFF', 'HIGH', 'MED', 'LOW'];
const soundDetUpdate = (nextValue: string) => ({
  soundDetect: nextValue,
});

const spotLightDurOptions = ['OFF', '30sec', '1m', '2m', '5m', '10m', '15m'];

const spotLightDurUpdate = (nextValue: string) => {
  return {
    spotLightDur: nextValue,
  };
};

const spotLightLevel = ['DIM', 'MEDIUM', 'MED', 'FULL'];

const spotLightLevelUpdate = (nextValue: string) => {
  return {
    spotLightLevel: nextValue,
  };
};

const spotLightEnableUpdate = (nextValue: boolean) => ({
  spotLight: nextValue,
});

const dayModeOptions = ['Off', 'On', 'Auto', 'Night', 'Day'];
const dayModeUpdate = (nextValue: string) => {
  let opt = '';
  switch (nextValue) {
    case 'Off':
      opt = 'false';
      break;
    case 'On':
      opt = 'true';
      break;
    case 'Auto':
      opt = 'auto';
      break;
    case 'Night':
      opt = 'night';
      break;
    case 'Day':
      opt = 'day';
      break;
  }
  return {
    dayMode: opt,
  };
};

const recordDurationOptions = ['SHORT', 'LONG'];
const recordDurationUpdate = (nextValue: string) => ({
  recordDur: nextValue,
});

const sirenDurationOptions = [
  '5 secs',
  '10 secs',
  '15 secs',
  '30 secs',
  '1 min',
  '2 mins',
  '5 mins',
];
const sirenDurationUpdate = (nextValue: string) => ({
  sirenDur: nextValue,
});

const timeStepUpdate = (nextValue: boolean) => ({
  timeStamp: nextValue,
});

const watermarkUpdate = (nextValue: boolean) => ({
  watermark: nextValue,
});

const watermarkFileOptions = ['pepper', 'momentum'];
const watermarkFileUpdate = (nextValue: string) => {
  let value = '';
  switch (nextValue) {
    case 'pepper':
      value = 'pepper.bmp';
      break;
    case 'momentum':
      value = 'momentum.bmp';
      break;
  }
  return {
    watermarkFile: value,
  };
};

const connectSoundUpdate = (nextValue: boolean) => ({
  connectSound: nextValue,
});

const liveStreamLEDsUpdate = (nextValue: boolean) => ({
  liveStreamLEDs: nextValue,
});

const privacyModeUpdate = (nextValue: boolean) => ({
  privacyMode: nextValue,
});

const sdcardWriteUpdate = (nextValue: boolean) => ({
  sdcardwrite: nextValue,
});

const ptzLeft = {
  clazz: 'ptz_move',
  data: {
    direction: 'left',
  },
};
const ptzRight = {
  clazz: 'ptz_move',
  data: {
    direction: 'right',
  },
};
const ptzUp = {
  clazz: 'ptz_move',
  data: {
    direction: 'up',
  },
};
const ptzDown = {
  clazz: 'ptz_move',
  data: {
    direction: 'down',
  },
};
const ptzStop = {
  clazz: 'ptz_stop',
  data: undefined,
};
const ptzHome = {
  clazz: 'ptz_home',
  data: undefined,
};

const debugUpdate = (nextValue: boolean) => ({
  debug: nextValue,
});

const rebootEventType = 'Reboot';
const resetEventType = 'Reset';

const CameraInfoClazz = 'info';
const CameraSignalClazz = 'signalInfo';
const CameraCPUClazz = 'cpu';
const CameraConfigClazz = 'config';
const CameraSDCardInfoClazz = 'sdCardInfo';
const uploadLogEvent = 'UploadLogFile';
const updateFirmwareEvent = 'OTA';
const channel = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
];

const cameraConfig = {
  snapshotEvent,
  startRecordVideoEvent,
  stopRecordVideoEvent,
  playSirenEvent,
  stopPlaySirenEvent,
  imageMotionEnableUpdate,
  soundDetectionEnableUpdate,
  soundDetectionEvent,
  doorbellPressEvent,
  flip0,
  flip1,
  flip2,
  flip3,
  timeZoneOptions,
  timeZoneUpdate,
  imageQualityOptions,
  imageQualityUpdate,
  imageMotionOptions,
  imageMotionUpdate,
  soundDetOptions,
  soundDetUpdate,
  dayModeOptions,
  dayModeUpdate,
  spotLightDurOptions,
  spotLightDurUpdate,
  spotLightLevel,
  spotLightLevelUpdate,
  spotLightEnableUpdate,
  recordDurationOptions,
  recordDurationUpdate,
  sirenDurationOptions,
  sirenDurationUpdate,
  timeStepUpdate,
  watermarkUpdate,
  watermarkFileOptions,
  watermarkFileUpdate,
  connectSoundUpdate,
  liveStreamLEDsUpdate,
  privacyModeUpdate,
  sdcardWriteUpdate,
  ptzLeft,
  ptzRight,
  ptzUp,
  ptzDown,
  ptzStop,
  ptzHome,
  PTZMotionTracking,
  PTZMotionTrackingUpdate,
  debugUpdate,
  rebootEventType,
  resetEventType,
  CameraInfoClazz,
  CameraSignalClazz,
  CameraCPUClazz,
  CameraConfigClazz,
  CameraSDCardInfoClazz,
  uploadLogEvent,
  updateFirmwareEvent,
  channel,
};

export default cameraConfig;
