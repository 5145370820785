import React from 'react';
import { Box, Grid } from '@mui/material';
import { v4 } from 'uuid';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import CommonHeader from '../../Partials/CommonHeader';
import ConfigControl from './Partials/ConfigControl';
// import TestOMaticControl from './Partials/TestOMaticControl';
import CameraInfoControl from './Partials/CameraInfoControl';
// import MotionCalibrationControl from './Partials/MotionCalibrationControl';
import StreamingControl from './Partials/StreamingControl';
import EventControl from './Partials/EventControl';
import LoadingCard from '../../../../Components/LoadingCard';
import CommandPanel from '../../Partials/command-panel';

export default function CameraValidation(props: ValidationProps) {
  const [deviceConfig, setDeviceConfig] = React.useState<
    CameraConfig | undefined
  >();
  const [cameraInfo, setCameraInfo] = React.useState<CameraInfo | undefined>();
  const [latestNotifData, setLatestNotifData] = React.useState<
    NotifData | undefined
  >();
  const [numberOfChannels, setNumberOfChannels] = React.useState<number>(0);
  const [events, setEvents] = React.useState<MoveEvent[]>([]);
  const [channel, setChannel] = React.useState('');
  const handleCameraInfo = (data: any) => {
    if (data && data.config && data.config.parms) {
      setLatestNotifData(data.config.parms);
    } else {
      setLatestNotifData(data);
    }
  };
  props.moveClient.onCameraConfig = handleCameraInfo;
  props.moveClient.onCameraInfo = handleCameraInfo;
  props.moveClient.onSignalInfo = handleCameraInfo;
  props.moveClient.onCameraCpu = handleCameraInfo;
  props.moveClient.onSdCardInfo = handleCameraInfo;

  React.useEffect(() => {
    setNumberOfChannels(
      latestNotifData?.channel_count ? latestNotifData.channel_count : 0,
    );
  }, [latestNotifData]);
  const handleNewEvent = (data: any) => {
    let nextEvents = events;
    try {
      if (data.event.parent === props.device.addresses[0].address) {
        nextEvents = [data.event, ...events];
      }
    } catch {
      nextEvents = [
        {
          account: '',
          created: new Date().toString(),
          id: v4(),
          media_key_1: '',
          name: '',
          parent: '',
          type: `updateConfig - ${JSON.stringify(data)}`,
          updated: new Date().toString(),
        },
        ...events,
      ];
    }
    setEvents(nextEvents);
  };
  props.moveClient.onNewEvent = handleNewEvent;
  props.moveClient.onNotification = handleNewEvent;

  const onConfigUpdate = (newConfig: any) => {
    // this is the full config returned from move
    if (newConfig && newConfig.parms) {
      newConfig.parms.deviceId = newConfig.device_id;
      setDeviceConfig({
        ...newConfig.parms,
        createdAt: newConfig.created,
        lastPairedAt: newConfig.lastPairedAt,
        state: newConfig.state,
      });
      setLatestNotifData(newConfig.parms);
    }

    // this is a single config change from move
    if (newConfig && !newConfig.parms) {
      setDeviceConfig({
        ...deviceConfig,
        ...newConfig,
      });
      handleNewEvent(newConfig);
    }
  };
  props.moveClient.onConfigUpdate = onConfigUpdate;

  const initialDeviceConfigCG = React.useCallback(() => {
    if (!deviceConfig) {
      let deviceId = '';
      if (props.device.addresses.length !== 0) {
        deviceId = props.device.addresses[0].address;
      }
      if (deviceId !== '') {
        props.moveClient.getConfig(deviceId);
      } else {
        console.error('could not determine device id');
      }
    }
  }, [deviceConfig, props.device, props.moveClient]);
  React.useEffect(initialDeviceConfigCG, [initialDeviceConfigCG, props.device]);

  const cameraInfoCB = React.useCallback(() => {
    props.moveClient.onCameraInfo = (data: any) => {
      setCameraInfo(data);
    };
    // props.moveClient.CameraNotif(props.device.addresses[0].address, 'info', '');
  }, [props.moveClient, setCameraInfo]);
  React.useEffect(cameraInfoCB, [cameraInfoCB]);

  const onConfigChange = () => {
    // give it a timeout to let move process the config update
    setTimeout(() => {
      // props.moveClient.getConfig(props.device.addresses[0].address);
    }, 1000);
  };

  const onSendCameraNotif = (clazz: string, data: any) => {
    let dataToSend = data;
    if (channel) {
      dataToSend = {
        ...data,
        channel: parseInt(channel),
      };
    }
    props.moveClient.SendNotif(
      props.device.addresses[0].address,
      clazz,
      dataToSend,
    );
  };
  const onSendCameraEvent = (eventType: string) => {
    props.moveClient.CreateEvent(props.device.addresses[0].address, eventType);
  };

  const selectedCameraConfig = React.useMemo(() => {
    function isXVRConfig(
      config: XVRConfig | CameraConfig | undefined,
    ): config is XVRConfig {
      if (!config) {
        return false;
      }
      const channelProp = `channel-${channel}`;
      return config && config.hasOwnProperty(channelProp);
    }

    if (isXVRConfig(deviceConfig) && channel) {
      return deviceConfig[`channel-${channel}`];
    }

    return deviceConfig!;
  }, [deviceConfig, channel]);

  const renderDevice = () => {
    return (
      <Grid item xs={12} container columnGap={4}>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column" rowGap={4}>
            <ConfigControl
              device={props.device}
              config={selectedCameraConfig}
              moveClient={props.moveClient}
              onConfigChange={onConfigChange}
              clearEvents={() => setEvents([])}
              numberOfChannels={numberOfChannels}
              setChannel={(ch: string) => setChannel(ch)}
              channel={channel}
            />
            <CommandPanel
              deviceId={props.device.addresses[0].address}
              moveClient={props.moveClient}
              onConfigChange={onConfigChange}
            />
            <CameraInfoControl
              device={props.device}
              onSendCameraNotif={onSendCameraNotif}
              onSendCameraEvent={onSendCameraEvent}
              info={cameraInfo}
              data={latestNotifData}
            />
            {/* <TestOMaticControl /> */}
            {/* <MotionCalibrationControl /> */}
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" flexDirection="column" rowGap={4}>
            <StreamingControl
              moveClient={props.moveClient}
              device={props.device}
              channel={channel ? parseInt(channel) : undefined}
            />
            <EventControl events={events} />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} container justifyContent="center" sx={{ pb: 4 }}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <CommonHeader
            Icon={<CameraAltOutlinedIcon fontSize="large" />}
            viewTitle={props.device.label}
            onChangeDevice={props.onChangeDevice}
          />
          {!deviceConfig && <LoadingCard msg="Getting Device Config" />}
          {deviceConfig && renderDevice()}
        </Box>
      </Grid>
    </Grid>
  );
}
