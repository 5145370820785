import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from '@mui/material';

import { MoveBrowserClient } from 'move-sdk';
import ExpandableCard from '../../../../../Components/ExpandableCard';

import cameraConfig from '../../../modules/cameraConfig';

const buttonProps: ButtonProps = {
  size: 'small',
  color: 'secondary',
  variant: 'outlined',
  fullWidth: true,
};

const selectProps: SelectProps = {
  fullWidth: true,
  size: 'small',
};

export default function ConfigControl(props: {
  device: MoveDevice;
  config: CameraConfig;
  moveClient: MoveBrowserClient;
  numberOfChannels: number;
  onConfigChange: () => void;
  clearEvents: () => void;
  setChannel: (ch: string) => void;
  channel: string;
}) {
  const [config, setConfig] = React.useState(props.config);
  const configChangeCB = React.useCallback(() => {
    setConfig(props.config);
  }, [setConfig, props.config]);
  React.useEffect(configChangeCB, [configChangeCB, props.config]);

  const [recordingVideo, setRecordingVideo] = React.useState(false);
  const [playingSiren, setPlayingSiren] = React.useState(false);

  const onConfigUpdate = (data: any) => {
    let configUpdates = data;
    if (props.channel) {
      configUpdates = { [`channel-${props.channel}`]: { ...config, ...data } };
    }
    props.moveClient.sendToCamera(
      props.device.addresses[0].address,
      'updateConfig',
      configUpdates,
    );
    props.onConfigChange();
  };
  const onEvent = (eventType: string) => {
    const selectedChannel = parseInt(props.channel);
    if (props.channel) {
      props.moveClient.CreateEvent(
        props.device.addresses[0].address,
        eventType,
        { channel: selectedChannel },
      );
      return;
    }
    props.moveClient.CreateEvent(props.device.addresses[0].address, eventType);
  };
  const onCameraNotif = (clazz: string, data: any) => {
    props.moveClient.SendNotif(props.device.addresses[0].address, clazz, data);
  };

  const onChannelUpdate = async (updChannel: string) => {
    props.setChannel(updChannel);
    props.clearEvents();
  };

  return (
    <>
      {props.numberOfChannels > 0 && (
        <ExpandableCard title="Select a Channel" initiallyOpen smallMargin>
          <Box width={1}>
            <Typography variant="caption">Channel</Typography>
            <Select
              {...selectProps}
              value={props.channel || ''}
              defaultValue={''}
              onChange={(e: SelectChangeEvent<any>) => {
                if (e.target.value) {
                  onChannelUpdate(e.target.value);
                }
              }}
            >
              <MenuItem sx={{ lineHeight: '10px' }} value="">
                &nbsp;
              </MenuItem>
              {cameraConfig.channel.map((o) => {
                return (
                  <MenuItem
                    sx={{ lineHeight: '10px', fontSize: '12px' }}
                    value={o}
                    key={`channel-${o}`}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </ExpandableCard>
      )}

      <ExpandableCard title="Camera Controls" initiallyOpen smallMargin>
        <Box display="flex" flexDirection="column" rowGap={1}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            columnGap={1}
          >
            <Button
              {...buttonProps}
              onClick={() => onEvent(cameraConfig.snapshotEvent)}
            >
              SnapShot
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                onEvent(
                  recordingVideo
                    ? cameraConfig.stopRecordVideoEvent
                    : cameraConfig.startRecordVideoEvent,
                );
                setRecordingVideo(!recordingVideo);
              }}
            >
              {recordingVideo ? 'Stop Recording Video' : 'Record Video'}
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                onEvent(
                  playingSiren
                    ? cameraConfig.stopPlaySirenEvent
                    : cameraConfig.playSirenEvent,
                );
                setPlayingSiren(!playingSiren);
              }}
            >
              {playingSiren ? 'Stop Playing Siren' : 'Play Siren'}
            </Button>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            columnGap={1}
          >
            <Button
              {...buttonProps}
              variant={config.imageMotionEnable ? 'contained' : 'outlined'}
              onClick={() => {
                onConfigUpdate(
                  cameraConfig.imageMotionEnableUpdate(
                    !!!config.imageMotionEnable,
                  ),
                );
              }}
            >
              Motion Detection
            </Button>
            <Button
              {...buttonProps}
              variant={config.soundDetectEnable ? 'contained' : 'outlined'}
              onClick={() => {
                onConfigUpdate(
                  cameraConfig.soundDetectionEnableUpdate(
                    !!!config.soundDetectEnable,
                  ),
                );
              }}
            >
              Sound Detection
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                onEvent(cameraConfig.doorbellPressEvent);
              }}
            >
              Doorbell Chime
            </Button>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography variant="caption">Camera Rotate</Typography>
            <Box display="flex" flexDirection="row" columnGap={2}>
              <Button
                {...buttonProps}
                variant={config.imageFlip === '0' ? 'contained' : 'outlined'}
                onClick={() => {
                  onConfigUpdate(cameraConfig.flip0);
                }}
              >
                0°
              </Button>
              <Button
                {...buttonProps}
                variant={config.imageFlip === '2' ? 'contained' : 'outlined'}
                onClick={() => {
                  onConfigUpdate(cameraConfig.flip2);
                }}
              >
                180°
              </Button>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            columnGap={1}
          >
            <Box width={1}>
              <Typography variant="caption">Time Zone</Typography>
              <Select
                {...selectProps}
                value={config.timeZone || ''}
                defaultValue={''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.timeZoneUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.timeZoneOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`timezone-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box width={1}>
              <Typography variant="caption">Watermark File</Typography>
              <Select
                {...selectProps}
                value={config.watermarkfile || ''}
                defaultValue={''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.watermarkFileUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.watermarkFileOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`watermark-file-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            columnGap={1}
          >
            <Button
              {...buttonProps}
              variant={config.timeStamp ? 'contained' : 'outlined'}
              onClick={() => {
                onConfigUpdate(
                  cameraConfig.timeStepUpdate(!!!config.timeStamp),
                );
              }}
            >
              Time Stamp
            </Button>
            <Button
              {...buttonProps}
              variant={config.watermark ? 'contained' : 'outlined'}
              onClick={() => {
                onConfigUpdate(
                  cameraConfig.watermarkUpdate(!!!config.watermark),
                );
              }}
            >
              Watermark
            </Button>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            columnGap={1}
          >
            <Box width={1}>
              <Typography variant="caption">Image Quality</Typography>
              <Select
                {...selectProps}
                value={config.imageQuality || ''}
                defaultValue={''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.imageQualityUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.imageQualityOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`image-quality-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box width={1}>
              <Typography variant="caption">Image Motion</Typography>
              <Select
                {...selectProps}
                value={config.imageMotion || ''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.imageMotionUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.imageMotionOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`image-motion-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box width={1}>
              <Typography variant="caption">sDetect</Typography>
              <Select
                {...selectProps}
                value={config.soundDetect || ''}
                defaultValue={''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.soundDetUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.soundDetOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`sound-det-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            columnGap={1}
          >
            <Box width={1}>
              <Typography variant="caption">Day Mode</Typography>
              <Select
                {...selectProps}
                value={config.dayMode || ''}
                defaultValue={''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.dayModeUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.dayModeOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`day-mode-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box width={1}>
              <Typography variant="caption">Record Duration</Typography>
              <Select
                {...selectProps}
                value={config.recordDur || ''}
                defaultValue={''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.recordDurationUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.recordDurationOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`record-duration-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box width={1}>
              <Typography variant="caption">Siren Duration</Typography>
              <Select
                {...selectProps}
                value={config.sirenDur || ''}
                defaultValue={''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.sirenDurationUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.sirenDurationOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`siren-duration-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            columnGap={1}
          >
            <Button
              {...buttonProps}
              onClick={() => {
                onConfigUpdate(
                  cameraConfig.privacyModeUpdate(!!!config.privacyMode),
                );
              }}
            >
              Privacy Mode
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                onConfigUpdate(
                  cameraConfig.sdcardWriteUpdate(!!!config.sdcardwrite),
                );
              }}
            >
              SDCard Write
            </Button>
          </Box>

          {/* <Typography variant="caption" sx={{ pt: 1, }}>
            SpotLight Config
          </Typography> */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            columnGap={1}
          >
            <Box width={0.5}>
              <Typography variant="caption">SpotLight Duration</Typography>
              <Select
                {...selectProps}
                value={config.spotLightDur || ''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    cameraConfig.spotLightDurUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.spotLightDurOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`spot-light-dur-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            {/* <Box width={1}>
              <Typography variant="caption">
                Level
              </Typography>
              <Select {...selectProps}
                value={config.spotLightLevel}
                onChange={(e: SelectChangeEvent<any>) => { onConfigUpdate(cameraConfig.spotLightLevelUpdate(e.target.value || '')) }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {cameraConfig.spotLightLevel.map((o) => {
                  return (<MenuItem value={o} key={`spot-light-level-${o}`}>{o}</MenuItem>)
                })}
              </Select>
            </Box> */}
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            columnGap={1}
          >
            <Box width={0.5}>
              <Button
                {...buttonProps}
                variant={config.imageMotionEnable ? 'contained' : 'outlined'}
                onClick={() => {
                  onConfigUpdate(
                    cameraConfig.spotLightEnableUpdate(!!!config.spotLight),
                  );
                }}
              >
                {!config.spotLight && <>SpotLight On</>}
                {!!config.spotLight && <>SpotLight Off</>}
              </Button>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            columnGap={1}
            sx={{ width: '100%', pt: 1 }}
          >
            <Box
              display="flex"
              flexDirection="column"
              rowGap={1}
              sx={{ width: '100%' }}
            >
              <Typography variant="caption">PTZ</Typography>
              <Box display="flex" flexDirection="row" columnGap={1}>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    onCameraNotif(
                      cameraConfig.ptzLeft.clazz,
                      cameraConfig.ptzLeft.data,
                    );
                  }}
                >
                  Left
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    onCameraNotif(
                      cameraConfig.ptzRight.clazz,
                      cameraConfig.ptzRight.data,
                    );
                  }}
                >
                  Right
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    onCameraNotif(
                      cameraConfig.ptzUp.clazz,
                      cameraConfig.ptzUp.data,
                    );
                  }}
                >
                  Up
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    onCameraNotif(
                      cameraConfig.ptzDown.clazz,
                      cameraConfig.ptzDown.data,
                    );
                  }}
                >
                  Down
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    onCameraNotif(
                      cameraConfig.ptzStop.clazz,
                      cameraConfig.ptzStop.data,
                    );
                  }}
                >
                  Stop
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    onCameraNotif(
                      cameraConfig.ptzHome.clazz,
                      cameraConfig.ptzHome.data,
                    );
                  }}
                >
                  Home
                </Button>
              </Box>
              <Button
                {...buttonProps}
                onClick={() => {
                  onConfigUpdate(
                    cameraConfig.PTZMotionTrackingUpdate(
                      !!!config.PTZMotionTracking,
                    ),
                  );
                }}
              >
                Auto Motion Tracking (
                {config.PTZMotionTracking ? 'Enabled' : 'Disabled'})
              </Button>
            </Box>
          </Box>

          <Typography variant="caption" sx={{ pt: 1 }}>
            Debug
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            columnGap={1}
          >
            <Button
              {...buttonProps}
              onClick={() => {
                onConfigUpdate(cameraConfig.debugUpdate(!!!config.debug));
              }}
            >
              Debug
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                onEvent(cameraConfig.rebootEventType);
              }}
            >
              Reboot
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                onEvent(cameraConfig.resetEventType);
              }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </ExpandableCard>
    </>
  );
}
