import React from 'react';
import { Alert, Box, Typography } from '@mui/material';

import { format } from 'date-fns';

import ExpandableCard from '../../../../../Components/ExpandableCard';

export default function EventControl(props: { events: MoveEvent[] }) {
  return (
    <>
      <ExpandableCard title="Events" initiallyOpen>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Alert variant="filled" severity="info">
            Only events during the session will be displayed here.
          </Alert>

          <Box display="flex" flexDirection="column">
            {props.events.map((e) => {
              return (
                <Box display="flex" flexDirection="row" key={e.id}>
                  <Typography variant="caption">
                    {format(new Date(e.created), 'MM/dd/yyyy pp')} -{' '}
                    <b>{e.type}</b>
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </ExpandableCard>
    </>
  );
}
